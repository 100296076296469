import type {
  ActionFunctionArgs,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction } from
"@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  redirect,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError } from
"@remix-run/react";
import stylesheet from "~/tailwind.css";
import swiperstylesheet from "~/swiper.min.css";
import i18next from "./utils/localization/i18next.server";
import { useChangeLanguage } from "remix-i18next/react";
import { i18nCookie } from "~/utils/cookies.server";
import { languages } from "./utils/helper";
import { z } from "zod";
import { redirectWithSuccess } from "remix-toast";
import { subscribeEmail } from "~/api/base.server";
import Loading from "./components/Loading";
import { ToastContainer } from "react-toastify";
import toastStyles from "react-toastify/dist/ReactToastify.css";
import NotFoundSection from "./components/NotFoundSection";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoadingSpinner from "./components/LoadingSpinner";

export const meta: MetaFunction = () => {
  return [
  { title: "Wearby" },
  {
    name: "description",
    content:
    "Garment-making platform that allows customers to produce their styles online and on-demand."
  },
  {
    property: "og:title",
    content: "Wearby"
  },
  {
    property: "og:url",
    content: "https://wearby.app"
  },
  {
    property: "og:type",
    content: "website"
  },
  {
    property: "og:site_name",
    content: "Wearby"
  },
  {
    property: "og:description",
    content:
    "Garment-making platform that allows customers to produce their styles online and on-demand."
  },
  {
    property: "og:image",
    content: "https://wearby.app/images/og-image.jpeg"
  }];

};

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: stylesheet },
{ rel: "stylesheet", href: swiperstylesheet },
{ rel: "preconnect", href: "https://fonts.googleapis.com" },
{
  rel: "preconnect",
  href: "https://fonts.gstatic.com",
  crossOrigin: "anonymous"
},
{
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap"
},
{
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap"
},
{ rel: "stylesheet", href: toastStyles }];


export async function loader({ request }: LoaderFunctionArgs) {
  let locale = new URL(request.url).searchParams.get("locale");
  if (!locale) locale = await i18next.getLocale(request);

  return json(
    {
      locale,
      ENV: {
        BACKEND_HOST: process.env.BACKEND_HOST,
        DECRYPT_KEY: process.env.DECRYPT_KEY
      }
    },
    { headers: { "Set-Cookie": await i18nCookie.serialize(locale) } }
  );
}

export enum ActionTypes {
  SET_LOCALE = "SET_LOCALE",
  EMAIL_SUBSCRIBE = "EMAIL_SUBSCRIBE",
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  switch (formData.get("_action")) {
    case ActionTypes.SET_LOCALE:
      if (formData.get("locale")) {
        return redirect(request.headers.get("Referer") ?? "", {
          headers: {
            "Set-Cookie": await i18nCookie.serialize(formData.get("locale"))
          }
        });
      }
    case ActionTypes.EMAIL_SUBSCRIBE:
      const data = Object.fromEntries(formData);
      const formSchema = z.object({
        email: z.string().email()
      });
      const validatedData = formSchema.safeParse(data);

      if (!validatedData.success) {
        return json(validatedData.error.flatten().fieldErrors, { status: 400 });
      }

      if (
      await subscribeEmail({
        email: validatedData.data.email,
        name: (data.fullName as string),
        phone: (data.phone as string)
      }))

      return redirectWithSuccess(
        request.headers.get("Referer") ?? "/blogs/1",
        "subscribed"
      );
    default:
      return null;
  }
}

export const handle = {
  i18n: "common"
};

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <html>
          <head>
            <Meta />
            <Links />
          </head>
          <body>
            <NotFoundSection />
            <Scripts />
          </body>
        </html>);

    }

    // throw new Error(`${error.status} ${error.statusText}`);
  }

  throw new Error(error instanceof Error ? error.message : "Unknown Error");
};

const queryClient = new QueryClient();

export default function App() {
  const { locale, ENV } = useLoaderData<typeof loader>();
  useChangeLanguage(locale);
  const navigation = useNavigation();
  const location = useLocation();

  return (
    <html lang={locale} dir={languages[locale].dir}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-M9N2BXVEFH">
        </script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-M9N2BXVEFH');
            `
          }}>
        </script>

        <Meta />
        <Links />
      </head>
      <body className="no-scrollbar overflow-x-hidden">
        <QueryClientProvider client={queryClient}>
          <Outlet />
        </QueryClientProvider>

        <ToastContainer
          className="z-[999999999]"
          progressClassName="opacity-0"
          position="top-center"
          autoClose={1500} />


        {navigation.state === "loading" &&
        !location.pathname.includes("blogs") &&
        !location.pathname.includes("faq") ?
        <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-Secondary-Dark-Blue-db-500">
            <LoadingSpinner className="size-14 fill-white text-Secondary-Dark-Blue-db-500" />
          </div> :
        null}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }} />

        <Scripts />
        <LiveReload />
      </body>
    </html>);

}